import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { CaseStudiesTiles } from 'components/case-studies-tiles';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { Stats } from 'components/stats';
import { PATHS } from 'constants/paths';
import { array, number, string } from 'prop-types';

const STextWrapper = styled.div`
    text-align: center;
`;

const SParagraph = styled(Paragraph)`
    margin: 1.5rem 0 3.75rem;
`;

const SButtonWrapper = styled.div`
    margin-top: 3rem;
    display: flex;
    justify-content: center;
`;

const SStats = styled(Stats)`
    margin-bottom: 2.5rem;
`;

export const ServicesCaseStudiesSection = ({
    caseStudiesData,
    subtitleId,
    projectRangeTo,
}) => {
    return (
        <Container>
            <STextWrapper>
                <HeaderSecond>
                    <FormattedMessage id="single-service-case-studies-section.title" />
                </HeaderSecond>
                {subtitleId && (
                    <SParagraph>
                        <FormattedMessage id={subtitleId} />
                    </SParagraph>
                )}
            </STextWrapper>
            <SStats />
            <CaseStudiesTiles
                caseStudiesData={caseStudiesData}
                projectRangeTo={projectRangeTo}
            />
            <SButtonWrapper>
                <Button to={PATHS.CASE_STUDIES} component="gatsby-link">
                    <FormattedMessage id="single-service-case-studies-section.see-all" />
                </Button>
            </SButtonWrapper>
        </Container>
    );
};

ServicesCaseStudiesSection.propTypes = {
    caseStudiesData: array.isRequired,
    subtitleId: string.isRequired,
    projectRangeTo: number,
};

ServicesCaseStudiesSection.defaultProps = {
    projectRangeTo: 3,
};
